.home-black-left-panel {
  .content {
    width: 33%;
  }
}

.home-desktop-main-image-container {
  width: 100%;
  max-width: 1920px;
  height: 80%;
}

.home-desktop-main-image {
  width: 80%;
  height: 100%;
  background-size: cover;
}

.home-mobile-search-engine-container {
  margin-top: -40px;
}
