$primary-red: #d01408;
$dark-red: #af2912;
$light-red: #e13c32;
$blue: #2c50a0;
$green: #36b74f;
$white: #fff;
$grey-100: #f4f5f9;
$grey-200: #9eaacf;
$grey-300: #8d9bba;
$grey-400: #8890a1;
$grey-500: #4e5b74;
$grey-600: #4d5463;
$grey-700: #282c36;
$grey-800: #1f232c;
$black: #000;
$lightBlack: #282C36;

$breakpoints: (
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1550px,
);

@import 'components/placesAutocomplete';
@import 'components/footer';
@import 'components/menu';
@import 'components/searchEngine';
@import 'components/homeExpertsBlock';
@import 'components/reassuranceBanner';
@import 'components/energiesBanner';
@import 'components/homePartnerBlock';
@import 'components/newsCard';
@import 'components/agencyCard';
@import 'components/form';
@import 'components/radio';
@import 'pages/home';

.button-w-250 {
  width: 250px;
}

.absolute-left-top-corner {
  top: 0;
  left: 0;
}

.absolute-right-top-corner {
  top: 0;
  right: 0;
}

.index-2 {
  z-index: 2;
}

.d-none {
  display: none;
}

.background-cover {
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.h1 {
  font-size: 40px;
  font-family: 'Work Sans', sans-serif;
}

.h2 {
  font-size: 30px;
  font-family: 'Work Sans', sans-serif;
}

.h3 {
  font-size: 24px;
  font-family: 'Work Sans', sans-serif;
}

.h4 {
  font-size: 22px;
  font-family: 'Work Sans', sans-serif;
}

.h5 {
  font-size: 20px;
  font-family: 'Work Sans', sans-serif;
}

.h6 {
  font-size: 18px;
  font-family: 'Work Sans', sans-serif;
}

.h7 {
  font-size: 16px;
  font-family: 'Work Sans', sans-serif;
}

.lh-2 {
  line-height: 2.6rem;
}

.bg-cover {
  background-size: cover;
}

.two-lines-description {
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  line-height: 18px !important;
  font-weight: 400 !important;
  color: $grey-400 !important;
  font-size: 14px !important;
  text-align: left;
  word-break: break-all;
  max-height: 80px;
}

.description-text {
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  line-height: 18px !important;
  font-weight: 400 !important;
  color: $grey-400 !important;
  font-size: 14px !important;
  text-align: left;
  word-break: break-all;
  max-height: 80px;

  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  li,
  ol {
    line-height: 18px !important;
    font-weight: 400 !important;
    color: $grey-400 !important;
    font-size: 14px !important;
  }
}

.rotate-90 {
  transform: rotate(90deg);
}

.overflow-x-auto {
  overflow-x: auto;
}

.align-baseline {
  align-items: baseline;
}

.line {
  width: 100%;
  height: 1px;
}

.opacity-01 {
  opacity: 0.1;
}

.opacity-05 {
  opacity: 0.5;
}

.small-line-height {
  line-height: 22px;
}

.big-line-height {
  line-height: 44px;
}

.ws-pre-wrap {
  white-space: pre-wrap;
}

.ws-no-wrap {
  white-space: nowrap;
}

.flex-fill {
  flex: 1 1 0;
}

.wysiwyg-text {
  span,
  p,
  ul,
  li,
  ol {
    line-height: 22px !important;
    margin: 0 !important;
    text-align: justify;
  }
  p {
    margin: 1rem 0 !important;
  }
  a {
    text-decoration: underline;
  }
}

.wysiwyg-content {
  p {
    margin: 0 !important;
  }
}

.text-primary {
  color: $primary-red;
}

.red-link {
  .wysiwyg-text {
    a {
      text-decoration: none;
      color: $primary-red;
    }
  }
}

.top-radius {
  border-radius: 5px 5px 0 0;
}

.rotate-180 {
  transform: rotate(180deg);
}

.circle {
  border-radius: 50%;
}

.underline {
  text-decoration: underline;
}

.column-reverse {
  flex-direction: column-reverse;
}

.outline-grey {
  .btn-outline-transparent {
    color: $grey-400 !important;
    border: 1px solid $grey-400 !important;
    &:after {
      background: $grey-400;
    }
    &:hover {
      color: $white !important;
    }

  }
}

.grey-400-text-stroke {
  color: $grey-400;
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: $grey-400;
}