.footer-left-panel {
  width: 100%;
}

.footer-right-panel {
  width: 25%;
  height: 100%;
  z-index: 1;
}

.footer-legal-mentions {
  bottom: 0;
  left: 1rem;
}

.footer-panel-header {
  font-size: 1.1rem;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  font-weight: 700;
}