.home-partners-mobile-white-container {
  margin-top: -40px;
}

.home-partners-desktop-left-container {
  width: 75%;
}

.home-partners-desktop-left-content {
  width: 75%;
}

.home-partners-desktop-right-container {
  transform: rotate(180deg);
  width: 25%;
}

.home-partners-desktop-right-content {
  transform: rotate(-180deg);
}
