@import "theme";

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Work Sans', sans-serif;
  text-align: center;
  width: 100%;
  background-color: $grey-100;

  > div {
    max-width: 1920px;
    text-align: left;
    margin: 0 auto;
    overflow: hidden;
    background-color: $white;
  }
}

a {
  color: inherit;
  text-decoration: none;
  &:hover {
    opacity: 0.5;
  }
}

* {
  box-sizing: border-box;
}

button {
  border: none;
  margin: 0;
  text-decoration: none;
  background: transparent;
  cursor: pointer;
}

//button:hover,
//button:focus {
//  background: transparent;
//}

button:focus {
  outline: none;
}

h1 {
  line-height: 60px;
}

.invisible-scroll-bar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Chrome Safari Opera */
  }
}

.small-button-with-arrow {
  .button-with-arrow {
    padding: 0.5rem !important;
    font-size: 12px !important;
  }
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  background-color: $grey-800;
  color: $white;
  font-size: 10px;
  text-align: center;
  padding: 2px 5px;
  border-radius: 5px;
  white-space: nowrap;
  position: absolute;
  z-index: 1;
  top: 100%;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.white-06-text {
  color: rgba(255, 255, 255, 0.6);
}

.white-06-border {
  borderColor: rgba(255, 255, 255, 0.6);
}

.name-on-bill-input {
  .input-label {
    width: 263px;
  }
}

#tarteaucitronPersonalize2, #tarteaucitronAllDenied2 {
    background: #fff !important;
    color: #000 !important;
}

#tarteaucitronPersonalize2 > .tarteaucitronCheck::before {
  color: #000 !important;
}

#tarteaucitronAllDenied2 > .tarteaucitronCross::before {
  color: #000 !important;
}