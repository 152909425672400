
.news-card-container {
  .fgIndex {
    border-radius: 0 0 5px 5px;
  }
  &:hover {
    .arrow {
      display: flex;
    }
    h4 {
      color: $white;
    }
    .description-text {
      color: $white !important;

      span,
      p,
      ul,
      li,
      ol {
        color: $white !important;
      }
    }
  }
}

.news-card-height {
  h4 {
    height: 70px;
  }
}
