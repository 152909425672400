.location-search-input {
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  padding: 17px 6px;
  font-family: 'Work Sans', sans-serif;
  font-weight: 500;
  font-size: 16px;
  outline: none;
}

.location-icon {
  border-radius: 0 5px 5px 0;
  border-style: solid;
  border-width: 1px;
  border-left: none;
}

.places-autocomplete-dropdown-container {
  top: 60px;
  left: 0;
  z-index: 2;
}

.places-autocomplete-suggestion {
  background-color: $white;
  border-radius: 5px;
}

.places-autocomplete-suggestion--active {
  background-color: $grey-100;
  border-radius: 5px;
}
