.home-experts-main-image-container {
  width: 50%;
}

.home-experts-main-image-container-mobile {
  width: 100vw;
  height: 100vw;
}

.home-experts-body-text {
  width: 50%;
  text-align: justify;
}

.home-experts-body-text-mobile {
  text-align: justify;
}

.home-experts-logo-container {
  width: 50px;
  height: 50px;
}

.home-experts-text-container {
  margin-top: -40px;
}
